<template>
  <div>
    <div v-if="tableData">
      <el-table
          :data="tableData"
          :show-header="false"
          style="width: 100%"
      >
        <el-table-column
            prop="text"
            label="项目"
            width="110"
        >
        </el-table-column>
        <el-table-column
            prop="value"
            label="内容"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {getBulk4Details} from '@/api/eChnenergyTask'

export default {
  name: 'Bulk4',
  data() {
    return {
      tableData: undefined
    }
  },
  mounted() {
    document.title = '招标信息池-电煤招标详情'

    getBulk4Details({id: this.$route.params.id}).then(res => {
      this.tableData = [
        {text: '项目名称', value: res[0].title},
        {text: '报价截止时间', value: res[0].endDate},
        {text: '报名联系人', value: res[0].contactPerson},
        {text: '业务联系电话', value: res[0].contactPhone},
        {text: '备注', value: res[0].remarks},
      ]

    })

  },
  methods: {}
}
</script>

<style scoped>

</style>
